<template>
  <div class="contents">
    <div class="w-full px-2 pt-5">
      <vue-input-group
        v-model="item.content.set"
        :options="sets"
        labelKey="label"
        valueKey="key"
        prop="set"
        name="set"
        label="Product Sets"
        type="select"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ProductsSetBlockConfig',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sets: []
    }
  },
  methods: {
    async fetchSets () {
      await this.$api.get('products-sets')
        .then((response) => {
          response.data.forEach((element) => {
            this.sets.push({
              key: element.id,
              label: element.name
            })
          })
        }).catch((error) => {
          console.log(error)
          Vue.$toast.error('Something went wrong while fetching products sets. Please refresh the page and try again!')
        })
    }
  },
  mounted () {
    this.fetchSets()
  }
}
</script>

<style scoped>

</style>
